<!-- Toolbar -->
<div class="toolbar" role="banner">
  <div class="nav_left">
    <img src="assets/pocke_logo.png">
    <div class="nav_links" *ngIf="admin">
      <a routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">Home</a>
      <a routerLink="/escrow" routerLinkActive="active-link">Escrow</a>
      <a *ngIf="admin.isAdmin" routerLink="/admin" routerLinkActive="active-link">Admin Controls</a>
    </div>
  </div>
  <div *ngIf="admin" class="nav_right">
    <span>{{admin.email}}</span>
    <a (click)="logout()">Logout</a>
  </div>
</div>

<div class="container">
  <router-outlet></router-outlet>
  <app-loader></app-loader>
</div>