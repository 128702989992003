import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private globalLoader$: Subject<boolean> = new Subject();
  private localLoader$: Subject<void>;
  private count: number;


  getLocalLoader(count: number) {
    this.show();

    if (this.localLoader$) {
      this.resetLocal();
    }

    this.count = 0;
    this.localLoader$ = new Subject();
    this.localLoader$.subscribe(() => {
      this.count += 1;
      if (this.count >= count) {
        this.resetLocal();
        this.hide();
      }
    });

    return this.localLoader$;
  }

  getGlobalLoader() {
    return this.globalLoader$.asObservable();
  }

  show() {
    this.globalLoader$.next(true);
  }

  hide() {
    this.globalLoader$.next(false);
  }

  private resetLocal() {
    this.localLoader$.complete();
    this.localLoader$.unsubscribe();
    this.localLoader$ = null;
  }
}
