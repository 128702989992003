import { Component, OnInit } from '@angular/core';
import { AuthService, Admin } from './services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  admin: Admin;

  constructor(
    protected authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    // this.router.events.subscribe((event: RouterEvent) => {
    //   if (event instanceof NavigationStart) {
    //     this.loaderService.show();
    //   }
    // });

    this.authService.currentAdmin().subscribe((admin: Admin | null) => {
      this.admin = admin;
    });
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }
}
