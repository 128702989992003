import { tap, } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';


@Injectable()
export class UserInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    // return next.handle(req);
    return next.handle(req).pipe(tap((res: HttpResponse<{ status: boolean, [key: string]: any }>) => {
      if (res instanceof HttpResponse) {
        // console.log('interceptor', res);
        if (!res.body.status && res.body['errorCode'] === 'USER_UNAUTHORIZED') {
          localStorage.removeItem('auth');
          this.router.navigate(['login']);
        }
      }
    }));
  }
}
