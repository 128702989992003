export interface PockeResponse<T> {
  status: boolean;
  result?: T;
  message?: string;
}

export abstract class BaseService {
  protected getToken(): string {
    return localStorage.getItem('auth') || '';
  }

  protected validateResponse<T>(response: PockeResponse<T>) {
    if (!response.status) {
      throw new Error(response.message);
    }
    return response.result;
  }
}
