import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService, PockeResponse } from './base.service';


export interface Admin {
  email: string;
  fullname: string;
  token: string;
  role: string;
  isAdmin?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private adminUrl: string = `${environment.backendUrl}/admin`;
  private admin$ = new BehaviorSubject(null);


  constructor(private http: HttpClient) {
    super();
  }

  authLogin(): Observable<Admin> {
    // console.log('authLogin called');
    return this.http.get<PockeResponse<Admin>>(`${this.adminUrl}`, {
      headers: new HttpHeaders({
        Authorization: this.getToken(),
      })
    }).pipe(
      map(this.validateResponse),
      map((admin) => {
        if (!admin) {
          throw new Error("Something went wrong");
        }
        this.setAdmin(admin);
        return admin;
      })
    );
  }

  login(body: { email: string, password: string }): Observable<Admin> {
    return this.http.post<PockeResponse<Admin>>(`${this.adminUrl}/login`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      map(this.validateResponse),
      map(admin => {
        localStorage.setItem('auth', admin.token);
        this.setAdmin(admin);
        return admin;
      })
    );
  }

  logout() {
    console.log('logout called');
    return this.http.post<PockeResponse<void>>(`${this.adminUrl}/logout`, {}, {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('auth') || ''
      })
    }).pipe(
      map(this.validateResponse),
      map(() => {
        this.setAdmin(null);
        console.log('removing token');
        localStorage.removeItem('auth');
        return true;
      }),
    );
  }

  currentAdmin() {
    return this.admin$.asObservable();
  }

  inviteAdmin(body: { [key: string]: any }): Observable<void> {
    return this.http.post<PockeResponse<void>>(`${this.adminUrl}/invite`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken(),
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  checkToken(body: { [key: string]: any }): Observable<void> {
    return this.http.post<PockeResponse<void>>(`${this.adminUrl}/checktoken`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  setPassword(body: { [key: string]: any }): Observable<void> {
    return this.http.post<PockeResponse<void>>(`${this.adminUrl}/confirm`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }).pipe(
      map(this.validateResponse),
    );
  }

  private setAdmin(admin: Admin | null): void {
    if (admin) {
      admin.isAdmin = (admin.role && admin.role === 'admin' || admin.role === 'superadmin');
    }
    this.admin$.next(admin);
  }
}
